/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "初投稿。タイトルの通りGatsbyでブログを作ってみた。爆速サクサクでとてもいい。\nGraphQLを使った各種データの取得も拡張性が高く素晴らしいフレームワークだと思った。\n何よりチュートリアルがとても親切でわかりやすい。\nチュートリアルは", React.createElement(_components.a, {
    href: "https://www.gatsbyjs.com/docs/tutorial/"
  }, "こちら"), "。"), "\n", React.createElement(_components.p, null, "ちなみにスタイリングはTailwind CSSを使った。\n自分でCSSファイルを管理する必要がなく、スタイルに統一感を出すのも楽だった。\nでもCSSはやっぱり難しい。"), "\n", React.createElement(_components.p, null, "このブログでは、日記的に雑多なことを書いていこうと思う。\nフロントエンドの開発の勉強も兼ねて、このブログの機能拡張も継続的にやってみる予定。\nよろしくお願いします。"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
